import axios from 'axios';

export default {
  getFaultedPhasesWithPagination(pageNumber, pageSize, nameFilter, sortField, sortDesc) {
    return axios.get('/api/FaultedPhases', {
      params: {
        pageNumber, pageSize, nameFilter, sortField, sortDesc
      }
    });
  },
  addFaultedPhase: (faultedPhase) => axios.post('/api/FaultedPhases', faultedPhase),
  updateFaultedPhase: (faultedPhase) => axios.put(`/api/FaultedPhases/${faultedPhase.id}`, faultedPhase),
  deleteFaultedPhase: (id) => axios.delete(`/api/FaultedPhases/${id}`), 
};

